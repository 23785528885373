import { render, staticRenderFns } from "./LocationCarousel.vue?vue&type=template&id=9a9c74ee&scoped=true"
import script from "./LocationCarousel.vue?vue&type=script&lang=js"
export * from "./LocationCarousel.vue?vue&type=script&lang=js"
import style0 from "./LocationCarousel.vue?vue&type=style&index=0&id=9a9c74ee&prod&lang=scss"
import style1 from "./LocationCarousel.vue?vue&type=style&index=1&id=9a9c74ee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a9c74ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconCarouselArrowLeft: require('/opt/build/repo/components/icons/IconCarouselArrowLeft.vue').default,IconCarouselArrowRight: require('/opt/build/repo/components/icons/IconCarouselArrowRight.vue').default})
