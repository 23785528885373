
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import { mapState } from 'vuex';


export default {
  components: {
    VueSlickCarousel,
  },
  props: {
    blok: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState([
      'location',
    ]),
    gallery() {
      return this.location?.photoGallery
    },
    isMobile() {
      return this.$mq == "sm" || this.$mq == "md" ? true : false;
    },
    carouselSettings() {
      return {
        draggable: true,
        swipe: true,
        arrows: true,
        centerMode: false,
        variableWidth: true,
        infinite: false,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              dots: true,
              arrows: false,
              focusOnSelect: true,
              variableWidth: false,
              infinite: true
            },
          },
          {
            breakpoint: 2000,
            settings: {
            },
          },
        ],
      };
    },
    arrowIconColor() {
      if (this.blok.arrow_button_color === "purple") {
        return "#BAF54D";
      }

      if (this.blok.arrow_button_color === "bright-green") {
        return "#3D098E";
      }
    },
  },
};
